import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import axios from '@/util/http-common'
import store from '@/store'
//connecting: 0, connected: 1, reconnecting: 2, disconnected: 4
export default {
    install(Vue) {
        var signalRUrl = axios.defaults.baseURL.replace('api/', 'hubs/dashboard');
        signalRUrl = signalRUrl + "?access_token=" + store.getters["authUser/token"]
        var hubConnection = null;

        const ordersHub = new Vue();
        Vue.prototype.$ordersHub = ordersHub;

        const _signalR = {
            setupSignalRConnection() {
                hubConnection = new HubConnectionBuilder()
                    .withUrl(signalRUrl)
                    .withAutomaticReconnect([0, 5000, 10000, 30000])
                    .configureLogging(LogLevel.Information)
                    .build();

                hubConnection.on('OrderStatusChanged', (OrderId, BoxId, LineId, Status, Quantity) => {
                    let createdAt = new Date();
                    console.log('OrderStatusChanged', { OrderId, BoxId, LineId, Status, Quantity, createdAt: createdAt.toUTCString() });
                    ordersHub.$emit('status-changed', { OrderId, BoxId, LineId, Status, Quantity, createdAt: createdAt.toUTCString() });
                    ordersHub.$emit('console-log', 'OrderStatusChanged: OrderId:' + OrderId + ', BoxId:' + BoxId + ', LineId:' + LineId + ', Status:' + Status + ', Quantity:' + Quantity + ', createdAt:' + createdAt)
                });

                hubConnection.on('NewBarcodeLog', (queueName, barcode, boxId, bayName, message) => {
                    let createdAt = new Date();
                    console.log('NewBarcodeLog', { queueName, barcode, boxId, bayName, message, createdAt: createdAt.toUTCString() });
                    ordersHub.$emit('new-barcode-log', { queueName, barcode, boxId, bayName, message, createdAt: createdAt.toUTCString() })
                    ordersHub.$emit('console-log', 'NewBarcodeLog: queueName: ' + queueName + ", barcode:" + barcode + ", boxId:" + boxId + ", bayName:" + bayName + ", message:" + message + ", createdAt:" + createdAt);
                });

                hubConnection.on('NotifyDowloadedOrderLines', (queueName, bayName, quantity) => {
                    let createdAt = new Date();
                    console.log('NotifyDownloadedOrderLines', { queueName, bayName, quantity, createdAt });
                    ordersHub.$emit('notify-downloaded-order-lines', { queueName, bayName, quantity, createdAt: createdAt.toUTCString() })
                    ordersHub.$emit('console-log', 'NotifyDownloadedOrderLines: queueName: ' + queueName + ", bayName:" + bayName + ",quantity: " + quantity + ", createdAt:" + createdAt);
                });

                hubConnection.on('BoxQueued', (queueName, boxId) => {
                    let createdAt = new Date();
                    console.log('BoxQueued', { queueName, boxId, createdAt });
                    ordersHub.$emit('box-queued', { queueName, orderBoxId: boxId, createdAt: createdAt.toUTCString(), add: true })
                    ordersHub.$emit('console-log', 'BoxQueued: queueName: ' + queueName + ", orderBoxId:" + boxId + ", createdAt:" + createdAt);
                });

                hubConnection.on('BoxRemovedFromQueue', (queueName, boxId) => {
                    console.log('BoxRemovedFromQueue', { queueName, boxId });
                    ordersHub.$emit('box-queued', { queueName, orderBoxId: boxId, add: false })
                    ordersHub.$emit('console-log', 'BoxRemovedFromQueue: queueName: ' + queueName + ", orderBoxId:" + boxId + ", createdAt:" + new Date());
                });

                hubConnection.on('BoxProcessed', (queueName, boxId) => {
                    console.log('BoxProcessed', { queueName, boxId });
                    ordersHub.$emit('box-queued', { queueName, orderBoxId: boxId, add: false })
                    ordersHub.$emit('console-log', 'BoxProcessed: queueName: ' + queueName + ", orderBoxId:" + boxId + ", createdAt:" + new Date());
                });

                hubConnection.on('UpdateOrderStatus', (queueName, OrderId, BoxId) => {
                    console.log('UpdateOrderStatus', { queueName, OrderId, boxId });
                    ordersHub.$emit('status-changed', { OrderId, BoxId, LineId: 0, Status: 4, Quantity: 0 })
                    ordersHub.$emit('console-log', 'UpdateOrderStatus: OrderId:' + OrderId + ', BoxId:' + BoxId + ', Status:' + 4 + ', createdAt:' + new Date())
                });

                hubConnection.on('NewMessage', (message, username, messageId, connectionId) => {
                    console.log('NewMessage', { message, username, messageId, connectionId });
                    ordersHub.$emit('new-message', { message, username, messageId, connectionId })
                });

                hubConnection.onreconnecting(error => {
                    this.updateSignalRStatus();
                    console.assert(hubConnection.state === HubConnectionState.Reconnecting);
                    console.log("SignalR is Reconnecting...", error);
                });

                hubConnection.onreconnected(connectionId => {
                    this.updateSignalRStatus();
                    console.assert(hubConnection.state === HubConnectionState.Connected);
                    console.log("SignalR Reconnected with " + connectionId);
                });

                hubConnection.onclose(error => {
                    this.updateSignalRStatus();
                    console.assert(hubConnection.state === HubConnectionState.Disconnected);
                    console.log("SignalR Disconnected. ", error);
                });

                return this.startSignalRConnection(hubConnection);
            },

            async startSignalRConnection(connection) {
                try {
                    await connection.start();
                    this.updateSignalRStatus();
                    console.assert(connection.state === HubConnectionState.Connected)
                    return Promise.resolve("SignalR Connected.")
                } catch (err) {
                    this.updateSignalRStatus();
                    axios.get("/SMS/SendErrorMessage", {
                        params: {
                            message: "Connection attempt from " + store.state.authUser.username + " failed in vue.js.  error:" + err
                        }
                    }).then(response => {

                    }).catch(error => {

                    })
                    console.assert(connection.state === HubConnectionState.Disconnected)
                    setTimeout(() => this.startSignalRConnection(connection), 5000);
                    return Promise.reject("SignalR not Connected")
                }
            },

            sendMessageToBay(message, bay) {
                if (hubConnection != null && hubConnection.state === HubConnectionState.Connected) {
                    let messageId = hubConnection.invoke('SendMessageToGroup', message, bay, new Date())
                }
            },

            sendReplyMessage(message, connectionId) {
                if (hubConnection != null && hubConnection.state === HubConnectionState.Connected) {
                    let messageId = hubConnection.invoke('SendMessageToConnection', message, connectionId)
                }
            },

            updateSignalRStatus() {
                if (hubConnection != null && hubConnection.state === HubConnectionState.Connected) {
                    ordersHub.$emit('signal-r-status-changed', true);
                } else {
                    ordersHub.$emit('signal-r-status-changed', false);
                }
            }
        }

        _signalR.setupSignalRConnection();
        Vue.prototype.$signalR = _signalR;
    }
}
