<template>
  <v-container>
    <v-row align="center">
      <span>
        Copyright &copy; {{ currentYear }}
        <a
          id="companyLink"
          target="_blank"
          href="https://www.softclient.com.au/"
          >www.softclient.com.au</a
        >

        all rights reserved.
      </span>
      <v-col class="text-right">
        <!-- <span>Version 21.03.10.0</span> -->
        <span>Version B.E.{{ bVersion }} : F.E.{{ fVersion }}</span>
        <!-- <span>Version B.E.22.05.16.00 : F.E.{{ fVersion }}</span> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserDataService from "@/services/UserDataService";
import { version } from "../../package";

export default {
  name: "Links",

  data: () => ({
    bVersion: "",
    fVersion: version,
    currentYear: new Date().getFullYear(),
  }),
  created() {
    this.getVersion();
  },
  methods: {
    getVersion() {
      UserDataService.version().then((response) => {
        this.bVersion = response.data.version;
        let frontVersion = localStorage.getItem("fVersion");
        let backVersion = localStorage.getItem("bVersion");
        if (frontVersion && backVersion) {
          if (frontVersion !== this.fVersion || backVersion !== this.bVersion) {
            window.location.reload();
          }
        }
        localStorage.setItem("fVersion", this.fVersion);
        localStorage.setItem("bVersion", this.bVersion);
      });
    },
  },
};
</script>

<style lang="sass" scoped>
#companyLink
  color: #4caf50 !important
  text-decoration: none
</style>
