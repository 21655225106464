<template>
  <v-fade-transition mode="out-in">
    <div>
      <router-view />
      <v-app style="height: 0; max-width: 0">
        <message-alert />
      </v-app>
    </div>
  </v-fade-transition>
</template>

<script>
// Styles
  import '@/styles/overrides.sass'
  import MessageAlert from "./views/components/messageAlert.vue";

  export default {
    name: 'App',
    components: { MessageAlert },
    metaInfo: {
      title:
        'myOrders | SoftClient.com.au: supplier of barcode and RFID scanners &amp; printers',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  }
</script>
