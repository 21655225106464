import axios from 'axios'
import store from '../store'
import router from '../router'

const Url = 'https://myorders-test.myweb.net.au/api/'
//const Url = 'https://localhost:7083/api/'
// const Url = 'https://localhost:5001/api/'
//const Url = 'https://myweb.net.au/api/';
//const Url = 'https://myordersmmc-backup.azurewebsites.net/api/'
//const Url = 'https://myorders-backup.azurewebsites.net/api/'
//const Url = window.location.origin + "/api/"

const instance = axios.create({
    baseURL: Url,
    headers: {
        'Content-type': 'application/json',
    },
})

instance.interceptors.request.use(
    config => {
        if (store.getters["authUser/isLoggedIn"]) {
            config.headers.Authorization = 'Bearer ' + store.getters["authUser/token"]
        }
        return config
    }, error => Promise.reject(error));

instance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        const status = error.response.status;

        if (status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (store.getters["authUser/isRefreshing"]) {
                store.dispatch("authUser/getAccessToken")
                    .then(() => {
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + store.getters["authUser/token"];
                        return instance.request(originalRequest);
                    })
                    .catch(() => {
                        router.push('/login')
                    })
            } else {
                router.push('/login')
            }
        }

        return Promise.reject(error);
    });

export default instance
