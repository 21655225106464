// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { authUser } from '../store/modules'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('../layouts/default/Index'),
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: () => import('../views/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'test',
          name: 'Test',
          component: () => import('../views/Test'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'users',
          name: 'Users',
          component: () => import('../views/Users'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'create-users',
          name: 'CreateUsers',
          component: () => import('../views/CreateUsers.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'orders',
          name: 'Orders',
          component: () => import('../views/Orders'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'csv-orders',
          name: 'CSV Orders',
          component: () => import('../views/CsvOrders'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'secondary-orders',
          name: 'Archived Orders',
          component: () => import('../views/SecondaryOrders'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'products',
          name: 'Products',
          component: () => import('../views/Products'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'zones',
          name: 'Bays',
          component: () => import('../views/Zones'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'settings',
          name: 'Settings',
          component: () => import('../views/Settings.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'csvErrors',
          name: 'CsvErrors',
          component: () => import('../views/CsvErrors.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'devices',
          name: 'Devices',
          component: () => import('../views/Devices.vue'),
        },
        {
          path: 'logs',
          name: 'Logs',
          component: () => import('../views/Logs.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'console-logs',
          name: 'ConsoleLogs',
          component: () => import('../views/ConsoleLogs.vue'),
        },
        {
          path: 'device-logs',
          name: 'Device logs',
          component: () => import('../views/DeviceLogs.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'pickers-report',
          name: 'Pickers Report',
          component: () => import('../views/PickersReports.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'box-picking-report',
          name: 'Box Picking Report',
          component: () => import('../views/BoxPickingReports.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'individual-picking-report',
          name: 'Individual Picking Report',
          component: () => import('../views/IndividualPickingReports.vue'),
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: '/login',
      component: () => import('../layouts/page/Index'),
      children: [
        {
          path: '',
          name: 'Login',
          component: () => import('../views/Login'),
        },
      ],
    },
    {
      path: '/device-login',
      component: () => import('../layouts/page/Index'),
      children: [
        {
          path: '',
          name: 'Device Login',
          component: () => import('../views/DeviceLogin'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (from.name && from.name === 'Login') {
      next();
    } else {
      store.dispatch('authUser/authenticate').then(() => {
        next();
      }).catch(() => {
        next('/login');
      })
    }
  } else {
    next()
  }
})

export default router
