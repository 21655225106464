// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  forceMini: false,
  mini: true,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Orders',
      icon: 'mdi-clipboard-list ',
      to: '/orders/',
    },
    {
      title: 'Products',
      icon: 'mdi-dropbox ',
      to: '/products/',
    },
    {
      title: 'Bays',
      icon: 'mdi-lan',
      to: '/zones',
    },
    {
      title: 'Users',
      icon: 'mdi-account-group ',
      to: '/users/',
    },
    {
      title: 'Reports',
      icon: 'mdi-chart-box  ',
      to: '/reports/',
      items: [
        {
          title: 'Pickers Report',
          icon: 'mdi-chart-box ',
          to: '/pickers-report/',
        },
        {
          title: 'Box Picking Report',
          icon: 'mdi-chart-box ',
          to: '/box-picking-report/',
        },
        {
          title: 'Individual Picking Report',
          icon: 'mdi-chart-box ',
          to: '/individual-picking-report/',
        },
      ]
    },
    {
      title: 'Logs',
      icon: 'mdi-clipboard-list',
      to: '/logs/',
    },
  ],
  defaultItems: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Orders',
      icon: 'mdi-clipboard-list ',
      to: '/orders/',
    },
    {
      title: 'Products',
      icon: 'mdi-dropbox ',
      to: '/products/',
    },
    {
      title: 'Bays',
      icon: 'mdi-lan',
      to: '/zones',
    },
    {
      title: 'Users',
      icon: 'mdi-account-group ',
      to: '/users/',
    },
    {
      title: 'Logs',
      icon: 'mdi-clipboard-list',
      to: '/logs/',
    },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
