<template>
  <v-dialog persistent v-model="dialog" max-width="350px">
    <v-card>
      <v-card-title class="headline">
        <div style="display: flex">
          <img src="../../assets/comments.png" width="48" alt="" />
          <div class="mt-1 ml-2">Message</div>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="text-center">
          <div>{{ time }}</div>
          <div style="font-size: 18px" class="mt-2">
            <div>
              <strong> Message from {{ sender }}: {{ message }}</strong>
            </div>
          </div>
          <v-btn
            rounded
            width="200"
            class="mt-2"
            color="success"
            @click="close"
          >
            OK
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  name: "MessageAlert",
  data: () => ({
    dialog: false,
    message: "",
    time: "",
    sender: "",
    audio: new Audio(
      "https://mytaxis.azurewebsites.net/Resources/Sound/Message.mp3"
    ),
  }),
  created() {
    this.$ordersHub.$on("new-message", this.newMessage);
  },
  onDestroy() {
    this.$ordersHub.$off("new-message", this.newMessage);
  },
  methods: {
    newMessage(data) {
      console.log("messageAlert", data);
      this.audio.play();
      this.sender = data.username;
      this.time = moment().format("MMMM Do YYYY, h:mm:ss a");
      this.message = data.message;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.audio.pause();
    },
    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY, h:mm:ss a");
      } else {
        return "";
      }
    },
  },
};
</script>
